import request from '@/utils/request'

/**
 * 获取员工销售额报表数据
 */
export const getTotalSalesChartData = data => {
  return request({
    url: '/data/getTotalSalesChartData',
    params: data
  })
}
