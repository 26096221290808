<template>
  <div>
    <el-card>
      <div class="header">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="员工名称">
            <el-input
              v-model="queryForm.fullName"
              placeholder="用于筛选(可空)"
            ></el-input>
          </el-form-item>
          <el-form-item label="日期范围" style="margin-right: 10px;">
            <el-date-picker
              v-model="queryForm.dateRange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="YYYY/MM/DD"
              value-format="x"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">查看报表</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div id="totalSalesChartData" style="width: 100%;height: 80vh;"></div>
    </el-card>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import * as echarts from 'echarts'
import { getTotalSalesChartData } from '../../api/operate-data'
import { ElMessage } from 'element-plus'

const queryForm = ref({
  fullName: '',
  dateRange: ''
})

const totalSalesChartDataObj = ref(null)
const getData = async () => {
  if (queryForm.value.dateRange == null) {
    ElMessage({
      message: '请选择日期范围',
      type: 'warning'
    })
    return
  }
  const result = await getTotalSalesChartData({
    sysUserName: queryForm.value.fullName,
    startDate: queryForm.value.dateRange[0],
    endDate: queryForm.value.dateRange[1]
  })
  const fullNames = []
  const totalMonery = []

  result.forEach((item, index, result) => {
    fullNames.push(item.fullName)
    totalMonery.push(item.totalMonery / 10000)
  })
  // 绘制图表
  totalSalesChartDataObj.value.setOption({
    title: {
      text: '效绩报表'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: fullNames
    },
    series: [
      {
        name: '单位:万',
        type: 'bar',
        data: totalMonery,
        label: {
          show: true,
          position: 'inside'
        }
      }
    ]
  })
}

onMounted(() => {
  // 基于准备好的dom，初始化echarts实例
  totalSalesChartDataObj.value = echarts.init(
    document.getElementById('totalSalesChartData')
  )
})
</script>

<style scoped></style>
